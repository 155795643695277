// Here you can add other styles
@font-face{
    font-family: fntgre;
    src: url(fntgre.ttf)
}

//EMPIEZA LA PAGINA PRINCIPAL
h1,h2,h3,h4,h5,h6,label,p,strong,span,a,div,button{
    font-family: fntgre;
}

h3{
   color: "#00619a";
}

.dropdown-item i {
    width: 6px;
}


.btn-primary {
    color: #fff;
    background-color: #00619a;
    border-color: #00619a;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #024e7a;
    border-color: #392258;
}
.btn-primary:hover{
    color: #fff;
    background-color: #a02366c2;
    border-color: #392258;
}

.btn-primary:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}

.button-login {  
    margin: 0 auto;
    color: #fff;
    background-color: #20172c;
    border-color: #20172c;
    border-radius: 20px;
    height: 35px;
}

.login-principal{
    width: 162%;
}


.button-login:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}

.login-principal:hover{
    width: 162%;
}


.button-login:hover{
    margin: 0 auto;
    margin: 0 auto;
    color: #fff;
    background-color: #394886;
    border-color: #394886;
}



.button-singup {
    width: 100%;
    color: #20172c;
    background-color: #fff;
    border-color: #fff;
    border-radius: 20px;
    height: 35px;
}


.button-singup:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}


.button-singup:hover{
    width: 100%;
    color: #fff;
    background-color: #cccccc;
    border-color: #cccccc;
}




.button-back {
    width: 150px;
    height: 35px;
    color: #20172c;
    background-color: #fff;
    border-color: #fff;
    border-radius: 20px;
    margin-top: 10px;
}


.button-back:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}


.button-back:hover{
  
    color: #fff;
    background-color: #cccccc;
    border-color: #cccccc;
}

.text-msg-categorias{
    font-size: 0.775rem;
    font-weight: 400;
    text-align: center;
    color: #606060;
}

.text-msg-categorias-title{
    margin-top: 10px;
    margin-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #acabab;
}


.button-ok {
    width: 100px;
    height: 28px;
    color: #ffffff;
    background-color: #b8c052;
    border-color: #b8c052;
    border-radius: 20px;
    margin-top: 10px;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
}


.button-ok:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}


.button-ok:hover{
  
    color: #fff;
    background-color: #a7ad49;
    border-color: #a7ad49;
}

.marginForm{

    margin: 25px 0 0 20px;
}




.button-next {
    width: 150px;
    height: 35px;
    color: #ffffff;
    background-color: #b8c052;
    border-color: #b8c052;
    border-radius: 20px;
    margin-top: 10px;
}


.button-next:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}
.marginForm{

    margin: 25px 0 0 20px;
}



.button-next:hover{
  
    color: #fff;
    background-color: #a7ad49;
    border-color: #a7ad49;
}



.button-read {
    width: 150px;
    height: 35px;
    color: #20172c;
    background-color: #fff;
    border-color: #20172c;
    border-radius: 20px;
    margin-top: 10px;
}


.button-read:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}




.button-read:hover{
  
    color: #20172c;
    background-color: #eee;
    border-color: #20172c;
}



.button-int {
    color: #fff;
    background-color: #20172c;
    border-color: #20172c;
    margin-right: 10px;
    padding: 3px 10px;
    border-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.button-int:hover{
    color: #fff;
    background-color: #20172c;
    border-color: #20172c;
    padding: 3px 10px;
   
}


.logoIso{
  position: absolute;
  top:10px;
  left: 10px;
  width: 40px;
  height: 40px;

}
.loginpage{
  background-color:#008000;

}
.contentlogin{
  width: 100%;
  height: calc( 100% - 100px );
 // background-color: #e5e5e6;
  align-items: center !important;
  margin-top: -100px;
  border-bottom-left-radius: 200px;
  padding-top: 16vh;
  background-repeat: no-repeat;
  background-size: auto;

}

.content-sing-up{
    width: 100%;
    height: calc( 100vh - 100px );
   // background-color: #e5e5e6;
    align-items: center !important;
    margin-top: -100px;
    border-bottom-left-radius: 200px;
    
    background-repeat: no-repeat;
    background-size: auto;
  
  }


.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    border-radius: 5px;
  
}
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn{
    border-radius: 5px;
  
}


.login-input-group{
  background-color: #fff; 
  border-bottom: 1.4px solid #eaeaea !important;
  min-width: 240px;
  height: 36px;
  margin-bottom: 10px;
}

.placeholder-style{
    .css-1wa3eu0-placeholder{
        color:black;
        font-size: 15px;
    }
    .css-2b097c-container{
        border-bottom: 0.5px dotted black !important;
    }

    .css-1uccc91-singleValue{
        color:black;
        font-size: 15px;
    }
}

.css-1pahdxg-control{
border-radius: 20px !important;
}
.select-id{

    .css-yk16xz-control{
        background: transparent;
        border: 0px;
        box-shadow: transparent;
    }
    .css-1pahdxg-control{
        background: transparent;
        border: 0px;
        border-color: transparent;
        box-shadow: 0 0 0 0 transparent;
    }
    .css-1pahdxg-control:hover{
        border-color: transparent;
        background: transparent;
        box-shadow: transparent ;
    }
}

.inputgroup-cedula{
    margin-right: 0.1vw;
    margin-left: 0.1vw;
    border: 0;
    border-bottom: 1.4px solid #eaeaea !important;
    .col{
        padding:0;
    }
    .col-cedula{
        padding-left: 0;
        #cedula-profesor{
            padding-left: 0;
        }
        .input{
            padding-left: 0;
        }
    }
}


.profile-input-group{
    background-color: #fafafa; 
    border-bottom: 1.4px solid #eaeaea !important;
    min-width: 200px;
    height: 36px;
    //margin-bottom: 10px;
    .datepicker-input{
        width: 48vw;
        height: 6vh;
        padding-left: 10px;
    }
    
  }

  .div-estudiante{
    .datepicker-input{
        width: 100%;
        height: 33px;
    }
  }

  .search-input-group{
    background-color: #fff; 
    border: 1px solid #392258 !important;
    border-radius: 20px;
    min-width: 154px;
    width: 100%;
    height: 36px;
 }
 
 .css-yk16xz-control .css-1pahdxg-control{
    border: 1px solid #392258 !important;
    border-radius: 20px !important;
    box-shadow: none !important;
 }
 .css-yk16xz-control .css-1pahdxg-control:hover{
    border: 1px solid #392258 !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }
  .input-search  .css-yk16xz-control{
    border: 1px solid #392258 !important;
    border-radius: 20px !important;
  }

  .input-search{
    height: 33px;
    border-radius: 20px;

   }
.description-input-group{
    background-color: #fff; 
    border: 1.2px solid #eaeaea !important;
    min-width: 154px;
    border-radius: 5px;

}

.description-input-profile-group{
    background-color: #fff; 
    border: 1px solid #ccc !important;
    min-width: 154px;

}

.register-tag-input-group{
    
    background-color: #fff; 
    border: 1px solid #392258 !important;
    height: 35px;
    padding: 3px;
    min-width: 254px;   

}


.register-tag-input-profile{
    
    background-color: #fff; 
    border: 1px solid #cccccc !important;
    
    padding: 3px;
    min-width: 154px;   

}

.categoria-tag-input{
    
    background-color: #fff; 
    border: 1px solid #cccccc !important;
    
    padding: 3px;
    min-width: 154px;   
    min-height: 160px;
    div.ReactTags__tagInput input.ReactTags__tagInputField, div.ReactTags__tagInput input.ReactTags__tagInputField:focus{
        border: 1px solid gray;    
    }
}

.modal-materias{
    div.ReactTags__tagInput input.ReactTags__tagInputField, div.ReactTags__tagInput input.ReactTags__tagInputField:focus{
        border: 0px solid gray;   
        display: none;
    }
}

.tags-materias{
    div.ReactTags__selected a.ReactTags__remove{
        display: none;
    }

    div.ReactTags__tagInput input.ReactTags__tagInputField, div.ReactTags__tagInput input.ReactTags__tagInputField:focus{
        display: none;
    }
}


.card-header{
    background-color: #eaeaeb;
}
.card{
    background-color: #fafafa;
    border-radius:20px;
}

.card-header:first-child {
    border-radius: calc(20px - 1px) calc(20px - 1px) 0 0;
}

.register-tag-input{
    
    background-color: #fff; 
    border: 1px solid #392258 !important;
    
    padding: 3px;
    min-width: 254px;   

}


.image-loader-input-group{
    
    background-color: #fff; 
    border: 0px solid #392258 !important;
    min-height: 40px;
    padding: 3px;

}

.input-profile{
    height: 33px;
    border: 0px solid #ffffff !important;
    padding: 0.25rem 0.35rem;
}

.expired{

.form-control{
  border: 1px solid #ccc !important;
}    
.border-card{
    border: 1px solid #ccc !important;

}
}
.input-expired{
    height: 33px;
    border: 0px solid #ffffff !important;
    padding: 0.25rem 0.35rem;
}

.form-control{

    border: 0px solid #ffffff !important;

}
.form-control:focus {
    color: #111111;
    background-color: #fff;
    border-color: #00619a;
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}
.input:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}

.css-2b097c-container{
    border: 0px solid #ffffff !important;
    height: 33px;
    width: 100%;
    
}

.button_recover{
   background-color: transparent;
   border: 0px;
   
}
.button_recover:active{
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0px !important;


}
.button_recover:hover{
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0px !important;

}
.button_recover:focus{
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0px !important;

}

.text-recover{
  color: #acabab !important;
  font-size: 0.7rem;
  
  margin-bottom: 5px;

}
.text-conect{
    color: #111111;
    font-size: 0.7rem;
    margin-top:10px;
    margin-left:5px;
  }
.input:focus {
    color: #5c6873;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem transparent;
}

.textarea{
    height: 116px !important;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border: 1px solid #ffffff !important;
    color: #000000;

}




.react-tags__search-input input{
    position: relative;
    width: 100px !important;
    height: 30px;
    margin-bottom: 0;
    border: 0px solid #ffffff !important;
    color: #000000;

}

.react-tags__search-input input:focus{
    border: 0px solid #ffffff !important;
   
}

.react-tags__selected-tag{
  background-color: #fff;
  border: 1px solid #392258 !important;
  border-radius: 5px;
}


.textarea:focus {
    color: #5c6873;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem transparent;
}


.Checkbox {
   
    top: 0px !important;
}

.line-login{
    background-color: #fff;
    height: 1px;
    width: calc( 50% - 20px );

}

.card-color{

    background-color: #ffffff;
    border: 0px;
    padding: 10px;
    border-radius: 10px;
}

.card-color1{
    margin-top: 10px;
    background-color: transparent;
    border: 0px;
    border-left: 1px solid #c8c8c8 !important;
}

.card-color2{
  flex: none !important;
  width: 60%;
  background-color: transparent;
  border: 0px;
  max-width: 550px;
  .card-body{
      position: relative;
      //width: 45vw;
      left:20px;
  }
}





.sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #fff;
    background: #fff;
}

.sidebar .nav-link.active{
    color: #fff !important;
    background-color: transparent;
    border-right: 3px solid #00cca9 !important;
}

.sidebar .nav-dropdown.open .nav-link{
  color: black !important;

}
.sidebar .nav-link .nav-icon{
   color: #00cca9 !important;
}


.nav-dropdown-items{
    background-color: #eaeaeb !important;

}

.text-singup{
    color: #000042;
    margin-bottom: 20px;
    margin-top: 25px;
    margin-left: 15px;
}
.text-login{
    color: #000042;
    margin-bottom: 40px;
}
.title-login{
    color: #000042;
    font-size: 1.8rem;
}
.text-register{
    color: #fff;
    font-size: 0.8rem;
}
.text-register-profile{
    color: #000;
    font-size: 0.8rem;
    font-family: fntgre;
}
.title-register{
    color: #fff;
    font-size: 1.8rem;

}
.hastisco{
    color: #fff !important;
    font-size: 1.2rem;
    
}

.hastisco-int{
    color: #392258 !important;
    font-size: 1.2rem;
}


.iconinput-address{
    width:30px;
    height:30px; 
    border-radius:0px;
    border: 0px solid #ffffff;
    margin-top:2px;
    margin-left:5px; 
    padding-left:7px;
    .fa{
        color: #b3b3b3;
        font-size: 16px !important;
    }
    background-color: transparent;
    border-top-right-radius: 0px !important; 
    border-bottom-right-radius:  0px !important; 
    

 }
 .iconinput-address-wa{
    width:35px;
    height:30px; 
    border-radius:0px;
    border: 0px solid #ffffff;
    margin-top:2px;
    margin-left:5px; 
    padding-left:7px;
    .fa{
        color: #b3b3b3;
        font-size: 16px !important;
    }
    background-color:transparent;
    border-top-right-radius: 0px !important; 
    border-bottom-right-radius:  0px !important; 
    

 }

.iconinput{
   width:30px;
   height:30px; 
   border-radius:0px;
   border: 0px solid #ffffff;
   margin-top:2px;
   margin-left:5px; 
   padding-left:7px;
   .fa{
       color: #b3b3b3;
       font-size: 22px !important;
   }
   background-color:#fff;
   border-top-right-radius: 0px !important; 
   border-bottom-right-radius:  0px !important; 
}

.react-tagsinput{

    border: 0px solid #fff !important;
}


label{
    margin-bottom: 0.3rem !important;
}


div.ReactTags__tags {
    position: relative;
    width: 100%;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 30px;
    width: 100%;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    min-width: 80px;
    border: 0px solid #eee;
    padding: 0 4px;
    background: transparent;


}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #00cca9;
    background: #00cca9;
    color:#ffffff;
    font-size: 13px;
    display: inline-block;
    padding-left: 15px;
    padding-right: 5px;
    margin: 3px 5px;
    cursor: move;
    border-radius: 5px;
    line-height: 1.75em;
    
}
div.ReactTags__selected a.ReactTags__remove {
    color: #fff;
    margin-left: 0px;
   
    width: 18px;
    font-size: 15px;
    margin-left: -10px;
    padding-right: 5px;
    margin-right: 5px;
    margin-top: 0px;
    font-size: 20px;
    line-height: 1.1em;
    border-right: 1px solid #00cca9;
    cursor: pointer;
    float: left;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}


.tags-user{
    .ReactTags__remove{
        display: none;
    }

    input{
        display: none;
    }
}

.tab-map{
    text-align: center;
    margin-top: 5px;
}

.tab-map-int{
   margin-left: 25px;

}
.table-package{
    max-width: 850px !important; 

}

.table-user{
  border: 0px solid #f0f3f5;
  .headertab{
      background: #f0f3f5;
      th{
        
        font-size: 12px;
        color: #666666;
        vertical-align: top;
        padding: 0.5rem;
      } 
  }


 

.fa{
    margin-left: auto;
    margin-right: auto;
}

  tr{
    border: 1px solid #bcbec0;
    
  } 
  th{
    border: 1px solid #bcbec0;
    color: #666666;
    font-size: 12px;
    padding: 0.5rem;
    
  } 

  td{
    border: 1px solid #bcbec0;
  } 
}
.page-item.disabled .page-link{
    border: 1px solid #392258;
    color: #392258;
}
.page-link:hover {
    z-index: 2;
    color: #392258;
    background-color: #fff;
    border-color: #392258;
}
.page-item active{
    background-color: #392258;
    border: 1px solid #392258;
}
.page-link{
    border: 1px solid #392258;
    color: #392258;
}
.page-item disabled{
    border: 1px solid #392258;
    color: #392258;
}
.page-item.active .page-link {
    z-index: 0;
    color: #fff;
    background-color: #392258;
    border-color: #392258;

}

.form-group {
     margin-bottom: 2px !important;
}

.fileContainer .chooseFileButton{

    max-height: 50px;
}

.text-dialog-recover{
    color: #20172c;
    padding-bottom: 20px;


}
.input_place{
   margin-top: 0px;
   border: 0px;
   height: 33px;
   width:  100%   !important  ;
   background-color: transparent !important;

}


.intl-tel-input{
    width:  calc( 100% - 34px );
}

.input_tel{
    height: 35px;
    width:  calc( 100% - 0px );
    font-size: 12px;
    border: 0px;

}


.content-place{
    width: calc( 100% - 35px );
    margin-top: 0;
}


.contentCategoria{
    width: 660px !important;
}


.contenButtonscategoria
{
    width:250px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;

   
}


@media (max-width: 2500px) {

    #label-sede{
        font-size: 24px;
    }
    
    .fileContainer .chooseFileButton{
      font-size: 12px !important;
      padding: 5px 16px !important;


    }

  

    .card-color2{
        padding-left: 0px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        
    }
    
    html body .app.flex-row.align-items-center {
         height: auto !important; 
        overflow-y: auto  !important; 
    }
    .container {
        max-width: 920px;
        padding: 15px !important;
        padding-top: 30px !important;
        padding-bottom: 30px;
    }
    .content-paquete{
        width: 850px;  
       
    }

    .paquete{
        width: 220px !important;
        margin-left: 20px !important;
        margin-right: 35px !important;
        margin-top: 10px;
        background: #e6e6e6;
        border-radius: 15px;
        padding-right: 5px;
        
       .cont-imag{
         width: 90px !important;
         position: absolute;
    
         background: #c9d154;
         left: -40px !important;
         top: 15px;
         border-radius: 15px;
         height: 170px;
         -webkit-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         -moz-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
            img{
               
                width: 90px !important;
                height: auto; 
                margin-left: -5px;
                margin-top: 30px;
            }
    
       }
    
      
      }
 
  }

  @media (max-width: 2000px) {

    html body .app.flex-row.align-items-center {
        height: auto !important; 
       overflow-y: auto  !important; 
   }

    .card-color2{
        padding-left: 0px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        
    }

    .content-paquete{
        width: 960px !important; 
         
    }

    .table-package{
        width: 850px;
    }

}

  @media (max-width: 1125px) {


    .table-package{
        width: 780px;
    }

    .content-paquete{
        width: 900px !important;  
    }
    .paquete{
        width: 210px !important;
        margin-left: 20px !important;
        margin-right: 35px !important;
        margin-top: 10px;
        background: #e6e6e6;
        border-radius: 15px;
        padding-right: 5px;
        
       .cont-imag{
         width: 80px !important;
         position: absolute;
    
         background: #c9d154;
         left: -40px !important;
         top: 15px;
         border-radius: 15px;
         height: 170px;
         -webkit-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         -moz-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
            img{
               
                width: 85px !important;
                height: auto; 
                margin-left: -5px;
                margin-top: 30px;
            }
    
       }
    
      
      }

}

  @media (max-width: 1025px) {

    .content-paquete{
        width: 830px !important;  
    }

}

@media (max-width: 899px){


    #label-sede{
        font-size: 20px;
    }
}

  @media (max-width: 878px){


    .table-package{
        width: 100%;
    }
    .content-paquete{
        width: 100% !important;  
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .paquete{
        width: 180px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 10px;
        background: #e6e6e6;
        border-radius: 15px;
        padding-right: 5px;
        
       .cont-imag{
         display: none;  
         width: 70px !important;
         position: absolute;
    
         background: #c9d154;
         left: -40px !important;
         top: 15px;
         border-radius: 15px;
         height: 170px;
         -webkit-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         -moz-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
         box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
            img{
               
                width: 70px !important;
                height: auto; 
                margin-left: -5px;
                margin-top: 30px;
            }
    
       }
    
      
      }
 
 }


 @media (max-width: 853px) {

    .ml-auto{
        width: 53px !important;
    }
    .text-bienvenido{
        display: none;
    }
}


@media (max-width: 768px){
    .preview{
        position: relative !important;
        margin-right: 0px !important;
     
     
     }
    .container {
        max-width: 740px;
    }
}



@media (max-width: 776px) {


    .contentlogin .content-sing-up{
        border-bottom-left-radius: 160px;
        padding-top: 5vh;
        padding-bottom: 10vh;
    }

    .contentCategoria{
        width: 380px !important;
    }

    /* #div-periodosSelect .label-periodosSelect{
        font-size: 17px !important;
    } */

    .btn-periodo{
        width: 48vw !important;
        height: 9vh !important;
    }

}
  
.logoheader{
   width: 50px;
   height: 45px;
}

@media (max-width: 640px) {
    #label-sede{
        font-size: 15px;
    }
    #button-crear-candidato{
        //height: 60px;
        width: 45vw;
    }
    #button-eliminar-candidato{
        //height: 60px;
        width: 45vw;
    }
}

@media (max-width: 542px) {
    #div-periodosSelect .row {
        text-align: center;
    }
}

@media (max-width: 527px) {
    #label-sede{
        font-size: 14px;
    }
    #div-periodos {
        .col-label-periodos {
            text-align: center !important;
        }
        .col-calendario-periodos{
            text-align: center !important;
        }

    }
}


@media (max-width: 490px) {

    /* .btn-periodo{
        width: 48vw !important;
        height: 9vh !important;
    } */

    #div-periodosSelect .label-periodosSelect{
        font-size: 17px !important;
    }

}



@media (max-width: 400px) {
    .app-header .navbar-nav .text-bienvenido{
        min-width: 170px !important;
        width: 170px !important;
        font-size: 12px;

    }
    .contenButtonscategoria{
       width: 230px;

    }
    .button-ok {
        width: 100px;
        height: 28px;
        color: #ffffff;
        background-color: #b8c052;
        border-color: #b8c052;
        border-radius: 20px;
        margin-top: 10px;
        padding: 0.2rem 0.5rem;
        font-size: 0.775rem;
   }


}
@media (max-width: 500px) {
    .logoheader{
        width: 48px;
        height: 40px;
     }
    .app-header .navbar-nav .text-bienvenido{
        min-width: 180px !important;
        width: 180px !important;
        font-size: 13px;
    }
    .contentCategoria{
        width: 260px !important;
    }
    .imagenpaquete{
        width: 220px !important;
     
     }
    .card-color2
    {
      width: 100%;
    }
    .contentlogin{
        border-bottom-left-radius: 0px !important;
        padding-top: 8vh !important;
        padding-bottom: 5vh !important;
    }
    .content-sing-up{
        border-bottom-left-radius: 0px !important;
        padding-top: 8vh !important;
        padding-bottom: 5vh !important;
    }
    .login-input-group{
        min-width: 2px;   
    }
}

@media (max-width: 505px) {
    .nav-logo-escudo{
        display: none;
    }
}

@media (max-width: 404px) {
    #label-sede{
        font-size: 12px;
    }
}

@media (max-width: 362px) {
    #label-sede{
        font-size: 10.5px;
    }
    #div-periodosSelect .label-periodosSelect{
        font-size: 15px !important;
    }
}

@media (max-width: 332px) {
    #label-sede{
        font-size: 10px;
    }
}



.zone_files{
    border: 1px solid #392258 !important;
    min-width: 254px;
    height: auto;
    background: #fff;

    .zone_drop{
     background: #ddd;
     height: 120px;
     padding-left: 10px;
     padding-right: 10px;
    
     input{
        height: 100%;
        width: 100%;
     }
     p{
       
         color: #00619a;
         margin-bottom: 0px;
     }
     span{

        margin-top: 0px; 
        color: #00619a;
     }
    }
    h3{
        font-size: 14px;
        padding-top: 5px;
        padding-left: 5px;
    }
    .file_drop{
        background: #ddd;
        border-radius: 0px;
        height: 50px;
        padding: 5px;
        margin: 5px;
        img{
            height: 40px;
            width: 60px;
      
        }
        span{
            margin-left: 5px;
        }

    }
   
    button{
        width: 30px;
        height: 30px;
        margin-top: 10px;
        border-radius: 15px;
        color: #392258;
        float: right;
        margin-right: 10px;
    }

}


.hwt-container {
	display: inline-block;
	position: relative;
	overflow: hidden !important;
	-webkit-text-size-adjust: none !important;
}

.hwt-backdrop {
	position: absolute !important;
	top: 0 !important;
	right: -99px !important;
	bottom: 0 !important;
	left: 0 !important;
	padding-right: 99px !important;
	overflow-x: hidden !important;
	overflow-y: auto !important;
}

.hwt-highlights {
	width: auto !important;
	height: auto !important;
	border-color: transparent !important;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
	color: transparent !important;
	overflow: hidden !important;
}

.hwt-input {
	display: block !important;
	position: relative !important;
	margin: 0;
	padding: 0;
	border-radius: 0;
	font: inherit;
	overflow-x: hidden !important;
	overflow-y: auto !important;
}

.hwt-content {
	border: 1px solid;
	background: none transparent !important;
}

.hwt-content mark {
	padding: 0 !important;
	color: inherit;
}


.icon-header{
    color: #20172c;
    font-size: 20px;
}
.avatar-content{

    margin-top: 10px;
  

    .avatarimg{
        position: relative;
        margin-right: auto;
        margin-left: auto;
        width: 90px;
     
        .icon-camera{
            position: absolute;
            bottom: -8px;
            right: 10px;
            padding: 5px;
            color: #ffffff;
            background-color: #20172c;
            font-size: 18px;
            border-radius: 5px;
            width: 30px;
            height: 28px;
            padding: 5px 6px;
        }

        .avatarbar{
        width: 80px;
        height: 80px;
        }
  }
}


.header-profile{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

}



.navbar{

  background-color: #20172c ;

}

.app-header .navbar-nav {
     width: 240px;
    
    float:right;

    .text-bienvenido{
      min-width: 190px;
      padding-right: 5px;
      text-align: right;
      color:#ffffff;
      span{
          color:#ffffff;
      }
    }
}
.app-header .nav-item{
    min-width: 40px; 
  

}



.profile-avatarbar{
   width: 44px;
  .avatarbar{
    float:right;
  
  }

}


.sidebar .nav-link .nav-icon {
    display: inline-block;
    width: 1.09375rem;
    margin: 0 0.5rem 0 0;
    font-size: 1.1rem;
    color: #fff;
    text-align: center;
}
.sidebar .nav-link{
    padding: 0.75rem 0.5rem;
    padding-left: 0.7rem;
    font-size: 13px;
}



  .myFile{
    
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
   img{
        width:180px;
        border-radius: 10px;
        height: auto;
        max-height: 180px;
        margin-left: 5px;
        margin-right: 5px;
   }
  .info *{
    margin: 5px;
   }

}
  .text_blue{
    color:#20172c;
    span{
        font-family: Arial;
    }
  }
  .text_blue:hover{
    color:#392258;
  }
  .btn_delete{

    color:#20172c;
    cursor: pointer;
    span{
        font-family: Arial;
    }

  }
  .btn_delete:hover{
    color:#392258;
  }
  

  .logs *{
    margin:1rem 0rem;
  }
  
  .log{
  
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1.2rem
  
  
  }

  .content-images{
    display: flex;
    align-items: stretch;
  }
  .imagencont{
    margin: 15px;
    background-color: #f1f0ef;
    border-radius: 10px;
  }


  .filepond--file-status .filepond--file-status-main{
    font-size: .66em !important;
    width: 180px;
    white-space: normal !important;
  }
  .filepond--file-status .filepond--file-status-sub{
    font-size: .6em !important;
    width: 180px;
  }
  .imagenpaquete{
    width: 300px;
    min-height: 200px; 
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    margin-top: 10px;
    text-align: center;
    background-color: #f1f0ef;
    border-radius: 10px;
  }

.autocomplete-dropdown-container{
  position: absolute;
  z-index: 1;
}


  .mapDialog{
    width: 600px;
  }

  .styles_overlay__CLSq-{


    background: rgba(0, 0, 0, 0.4);
  }


  .content-paquete{
    width: 900px;  
    margin-left: auto;
    margin-right: auto;
    padding-top: 0 ;
    padding-left: 20px;

  }
 

  .paquete{
    width: 250px;
    margin-left: 10px;
    margin-right: 50px;
    margin-top: 10px;
    background: #e6e6e6;
    border-radius: 15px;
    padding-right: 5px;
   
    

    
    .description-paquete{

        min-height: 100px !important;
            p{   
                font-size: 12rem;
                line-height: 1;
                margin-top: 0;
                margin-bottom: 4px;
            }
    }

 

   .cont-imag{
     width: 105px;
     position: absolute;

     background: #c9d154;
     left: -40px;
     top: 15px;
     border-radius: 15px;
     height: 170px;
     -webkit-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
     -moz-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
     box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
        img{
           
            width: 100px; 
            height: auto; 
            margin-left: -10px;
            margin-top: 30px;
        }

   }

  
  }







  .content-text{
   
    width: 165px;
    float: right;
    padding: 10px 5px;

    .icon{
      color:#2c5f9c;
    }
    .description{
        height: 60px;
    }
    h4{
      color:#2c5f9c !important;
      font-size: 16px !important;
      font-weight: 700 !important;
    }
    h5{
     color:#20172c !important;
     font-size: 17px !important;
     font-weight: 400 !important;
    }
    span{
        color:#000000 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
   }

   p{
    color:#000000 !important;
    font-size: 12px !important;
    font-weight: 300 !important;
   }


}


.modal-password{
    .styles_overlay__CLSq-{

        .styles_modal__gNwvD{
            padding: 0 !important;
       
         }

    }
  .styles_modal__gNwvD{
     padding: 0 !important;

  }


} 

.styles_modal__gNwvD{
    padding: 0 !important;
    border-radius: 5px;

 }


 .iconColor{

    color:#20172c !important;
    background-color: #c9cdd1;
    border-radius: 5px;
    font-size: 15px;

 }


 .button-tab {
    color:#20172c !important;
    background-color: #c9cdd1;
    border-color: #c9cdd1;
    margin-right: 3px;
    padding: 3px 5px;
    border-radius: 5px;
    max-width: 34px;
    width: 34px;
    margin-bottom: 3px;
}
.button-tab:focus{
    box-shadow: 0 0 0 0 rgba(175, 181, 186, 0.5);
}
   

.filepond--label-action{
    color:#20172c !important;


}





.carousel-indicators .active{
  background-color: #009dd8 !important;


}

.carousel-indicators li{

    border-radius: 5px;
    width: 10px;
    height: 10px;
    opacity: 1;
    background-color:  #fff !important;
    border: 1px solid  #009dd8;


}
.imagen-preview-carousel{
   border-radius: 15px;
   width: calc( 100% - 20px ) !important;
   margin-left: 10px;
   height: 35vh;

}

.preview{
   position: fixed;
   margin-right: 15px;
   margin-top: -70px;
   padding-left: 10px;
   padding-right: 10px;
   height: 100vh;
}
.scrollover{
    overflow: auto !important    ;

}

.background-preview{
    background-color: #e3dada;
    height: 900px;
}


.div-preview{
  position: relative;
  background-color: #ffffff;

}

.content-preview{
    position: absolute;
    width: calc( 100% - 36px);
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    z-index: 100;
    top:-50px;
    left: 18px;

    .content-text-preview{
       width: calc( 100% - 16px);
       position: relative; 
       margin: 10px 5px;
    }

    .carousel-indicators {
        position: relative;
        right: 0;
        top: 0; 
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }
  

    .icon{
        
        color:#20172c !important;
        font-size: 24px !important;
    }
      h4{
        color:#20172c !important;
        font-size: 20px !important;
        font-weight: 900 !important;
      }
      h5{
       color:#20172c !important;
       font-size: 12px !important;
       font-weight: 300 !important;
      }
      span{
          color:#000000 !important;
          font-size: 12px !important;
          font-weight: 400 !important;
     }
  
     p{
      color:#000000 !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      line-height: 1.15;
      padding: 3px 5px;
      
     }

     .contacto-content{

        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-gap: 10px;

     }

}


.paquete-int{
    position: relative;
    width: 80%;
    min-height: 240px;
    margin-top: 10px;
    background-color: #e6e6e6;
    border-radius: 15px;
    padding-right: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;


     
    .content-text{
   
        width: calc( 100% - 90px );
        float: right;
        padding: 10px 5px;
    
        .icon{
          color:#2c5f9c;
        }
        .description{
            height: auto;
        }
        h4{
          color:#2c5f9c !important;
          font-size: 18px !important;
          font-weight: 900 !important;
        }
        h5{
         color:#20172c !important;
         font-size: 17px !important;
         font-weight: 500 !important;
        }
        span{
            color:#000000 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
       }
    
       p{
        color:#000000 !important;
        font-size: 14px !important;
        font-weight: 300 !important;
       }
    
    
    }



        .cont-imag{
            width: 140px;
            position: absolute;
            background: #c9d154;
            left: -70px;
            top: 25px;
            border-radius: 15px;
            height: 190px;
            -webkit-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
            -moz-box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
            box-shadow: 13px 8px 22px -5px rgba(143,143,143,1);
                img{
                
                    width: 120px; 
                    height: auto; 
                    margin-left: -15px;
                    margin-top: 30px;
                }

        }



  
  }

  .add-card{
      
      border: 1px solid #ccc;
      padding: 20px 10px; 
      margin: 10px 5px;
       
  }


  .cardpay {
    width: 420px; 
    border: 1px solid #ccc;
    padding: 20px 10px; 
    margin: 5px;
    display: grid;
    grid-template-columns: auto auto 100px;
  
    grid-gap: 10px;

    .badge{

        font-size: 16px;
    }
    .typeCard{
       background-color: #2a639e !important;
       color: #fff; 

    }

    .button {
        color: #fff;
       
        background-color: #20172c;
        border-color: #20172c;
        margin-right: 10px;
        padding: 3px 10px;
        border-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .button:hover{
        color: #fff;
        background-color: #20172c;
        border-color: #20172c;
        padding: 3px 10px;
       
    }

  

  }

  .error-text{
    color: red;
   }

.error-text-white{
    color: #b8c052;
}
.form-select{
    .css-yk16xz-control{
        border: 0;
        border-bottom: 1.2px solid #eaeaea !important;
       
    }
}

 .public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    line-height: 14px;
}


.content_redes{
   margin-top: 20px; 
   display: grid;
   grid-template-columns: auto auto auto auto auto;
   grid-gap: 5px;
  
}   
.iconredes{
    background-color: #20172c !important;
    color: #ffffff !important;
    border-radius: 25px !important;
    font-size: 22px !important;
    padding: 8px !important;
    width: 40px !important;
    height: 40px !important;
    margin-left: auto;
    margin-right: auto;
}

.iconredes2{
    background-color: #20172c !important;
    color: #ffffff !important;
    border-radius: 25px !important;
    font-size: 28px !important;
    padding: 10px !important;
    width: 40px !important;
    height: 40px !important;
    margin-left: auto;
    margin-right: auto;
}


.proccess-pay{
   height: 250px;
   width: 300px;

}


.wrapperClassName .rdw-editor-wrapper{
    border: 1px solid #999;

    .rdw-editor-toolbar .toolbarClassName .rdw-editor-main{
        border: 1px solid #999 !important;
        padding: 10px !important;
    }
} 

.rdw-editor-toolbar .toolbarClassName .rdw-editor-main{
    border: 1px solid #999 !important;
    padding: 10px !important;
}


 .css-1rhbuit-multiValue{
  background-color: #fff !important;
  border: 1px solid #392258;
  border-radius: 4px !important;


}
.css-1alnv5e{

    color: #392258;
}

.icons{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;



}


.house-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/home.png);
}
.notificacion-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/notificacion.png);
}
.partner-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/partner.png);
}
.partner-iconint{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 0px !important;
    margin-right: 5px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/partner.png);
}

.list-aliados-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 5px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/list.png);
}
.teacher-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/profe.png);
}
.admin-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/boss.png);
}
.count-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/count.png);
}
.create-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/create.png);
}
.note-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/fail.png);
}
.upload-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/upload.png);
}
.exam-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/exam.png);
}
.link-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/chain.png);
}
.diag-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/diagram.png);
}
.download-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/download.png);
}
.student-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/book.png);
}
.web-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/global.png);
}
.vote-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/election.png);
}
.promote-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/ascent.png);
}
.menu-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/menu.png);
}
.add-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/add.png);
}


.packs-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/packs.png);
}


.stadistics-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/research.png);
}
.terms-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/terms.png);
}

.sidebar .nav{
    margin-bottom: 100px;
}

.words-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/words.png);
}

.flow-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/flow.png);
}

.map-icon{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px !important;
    margin-right: 15px !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/map.png);
}
.icon-view{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/see.png);
}
.icon-keychange{
    width: 25px !important;
    height: 25px;
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/lock_2.png);
}

.icon-pencil:before{
    font-size: 20px;
    filter: grayscale(1);
    margin-left: auto;
    margin-right: auto;
}



.icon-swichEnable{
    width: 74px !important;
    height: 25px;
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto !important;
    margin-right: auto !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/switch_on.png);
}

.icon-swichdisable{
    width: 74px !important;
    height: 25px;
    margin-top: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto !important;
    margin-right: auto !important;
    filter: grayscale(1);
    background-image: url(../assets/img/iconos/switch_off.png);
}

.sidebar-minimized .sidebar .nav{

    border-bottom-left-radius: 0px !important;

}




.sidebar .nav-dropdown-toggle::before{

  background-image: none;

}



.sidebar .nav-dropdown.open .nav-link:hover {
    background-color: #256aa2 !important;

}

.sidebar .nav-link:hover{
background-color: #256aa2 !important;

}


.scrollbar-container{

    border-bottom-left-radius: 150px;
    background-color: green;
}


.sidebar .nav-link:hover.nav-dropdown-toggle::before{

    background-image: none;
}


.table-user tr{

    border-top-right-radius:20px !important;
    border-top-left-radius:20px !important;
    border: 0 !important;
}


.table thead th {

    border-bottom: 0px solid #c8ced3;
}

.table th, .table td{

    border-top:0;
}


.table-user .headertab th{
    border:0;
    border-left: 1px solid #c8ced3;
    border-right:  1px solid #c8ced3;
    font-size: 14px;
    font-weight: 300;
    color:#364377;
}

.borde-left-table{
    border-top-left-radius:20px !important;
    border-left: 0px solid #c8ced3 !important;
    border-right: 0px solid #c8ced3 !important;
}

.borde-right-table{
    border-top-right-radius:20px !important;
    border-left: 0px solid #c8ced3 !important;
    border-right: 0px solid #c8ced3 !important;
}


.badge {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
  }

  .breadcrumb-item.active{
    color: #364377;
    font-size: 15px;
    font-weight: bolder;
  }


  .contentmsg{
      background-color: #eaeaeb;
      padding: 10px;
      margin-left: -30px;
      padding-top: 25px;
      min-height: 160px;
      text-align: center;
  }

  .input{
    height: 33px;
    background-color: transparent;
    
    border: 0px solid #ffffff !important;

}

.sidebar .nav-item{

    border-bottom: 1px solid #999999;
}


.styles_modal__gNwvD{

    border-radius: 20px !important;
}
.rdw-editor-wrapper{
   border: 1.2px solid #cccccc;
   padding: 5px;

}


.plan{
    position: relative;
    width: 230px;
    height: 520px;
}

.background-preview-plan{
 
    height: 490px;
    border-radius: 3px;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-bottom-width:0px;
    border-top-width:0px;
  
  }
  .button-preview{
    width: 50px;
    border-bottom-right-radius: 65px;
    width: calc( 100% - 20px );
    padding-left: 10px;
    color: #ffffff;
    font-weight: 800;
    font-size: 22px;
  }
  .content-preview-plan{
    background-color: #f2f2f2;
    text-align: center;
    height: 435px;
    width: calc( 100% + 2px);
    margin-left: -2px;
    border-top-left-radius: 68px;
   
    .content-text-preview{
       width: calc( 100% - 16px);
       position: relative; 
       margin: 10px 5px;
  
    }
  
    .div-preview{
      width: calc( 100% - 30px);
    
    }
  
  
    .icon{
        
        color:#20172c !important;
        font-size: 24px !important;
    }
  
      ul{
            font-size: 16px!important;
            font-weight: 900 !important;
            list-style: none;
            margin-block-start: 0em;
            padding-inline-start:15px;
            padding-inline-end:8px;
      }
  
      li{
          background: url(../assets/img/iconos/circle.png) no-repeat left 5px;
          padding-left: 1.5em;
          text-align: left;
          line-height: 1.3;
          margin-bottom: 3px;
          font-size: 12px!important;
          font-weight: 500 !important;
      }
  
    
      h4{
        font-size: 24px!important;
        font-weight: 800 !important;
        padding-right: 5px;
      }
      h5{
        font-size: 24px!important;
        font-weight: 800 !important;
      
      }
      h6{
        color:#ffffff !important;
        font-size: 26px !important;
        font-weight: 800 !important;
      
  
       }
      span{
          color:#000000 !important;
          font-size: 12px !important;
          font-weight: 400 !important;
     }
  
     p{
      color:#000000 !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      line-height: 1.15;
      padding: 0;
      
     }
  

     .contacto-content{

        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-gap: 10px;

     }

}

.app-body .main{

  background-color: #fafafa;

}

.si-no-badge{
    width: 48px;
}

.styles_modal__gNwvD{
    margin-top: auto;
    margin-bottom: auto;
}

.calendar-input-group{
    input{
        border:0;
        background: transparent;
    }
    input:focus{
        outline: none;
    }
}

#div-periodos{
    .col-label-periodos{
        text-align: right;
        margin-left: auto;
    }
    .col-calendario-periodos{
        margin-right: auto;
    }
    .row{
        padding: 40px;
        margin-left: auto;
        margin-right: auto;
    }
    .label-periodos{
        font-family: fntgre, cursive, sans-serif;
        font-size: 18px;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        font-style: normal;
        font-variant: small-caps;
        text-transform: uppercase;
    }
}

.div-primary-dashboard{


    .text-white{

        
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        color: #FFFFFF;
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 10;
        width: 30vw;
        height: 24vh;
        border: solid #337FED 1px;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        text-align: center;
    }
    
    .text-white:hover {
        border: solid #337FED 1px;
        background: #1E62D0;
        background-image: -webkit-linear-gradient(top, #1E62D0, #3D94F6);
        background-image: -moz-linear-gradient(top, #1E62D0, #3D94F6);
        background-image: -ms-linear-gradient(top, #1E62D0, #3D94F6);
        background-image: -o-linear-gradient(top, #1E62D0, #3D94F6);
        background-image: linear-gradient(to bottom, #1E62D0, #3D94F6);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        text-decoration: none;

    }

    .div-text-but{
        vertical-align: center;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
    }

    .btn-estudiante{
        float: right;
        background-image: -moz-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: -ms-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: -o-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: linear-gradient(to bottom, #63C2DE, #1E62D0);    
    }

    .btn-profesores{
        background: #99C624;
        background-image: -webkit-linear-gradient(top, #99C624, #526A16);
        background-image: -moz-linear-gradient(top, #99C624, #526A16);
        background-image: -ms-linear-gradient(top, #99C624, #526A16);
        background-image: -o-linear-gradient(top, #99C624, #526A16);
        background-image: linear-gradient(to bottom, #99C624, #526A16);
    }

    .btn-profesores:hover {
        border: solid #99C624 1px;
        background: #526A16;
        background-image: -webkit-linear-gradient(top, #526A16, #99C624);
        background-image: -moz-linear-gradient(top, #526A16, #99C624);
        background-image: -ms-linear-gradient(top, #526A16, #99C624);
        background-image: -o-linear-gradient(top, #526A16, #99C624);
        background-image: linear-gradient(to bottom, #526A16, #99C624);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        text-decoration: none;
    }
    .btn-directores{
        float: right; 
        background: #99C624;
        background-image: -webkit-linear-gradient(top, #99C624, #526A16);
        background-image: -moz-linear-gradient(top, #99C624, #526A16);
        background-image: -ms-linear-gradient(top, #99C624, #526A16);
        background-image: -o-linear-gradient(top, #99C624, #526A16);
        background-image: linear-gradient(to bottom, #99C624, #526A16);
    }

    .btn-directores:hover {
        border: solid #99C624 1px;
        background: #526A16;
        background-image: -webkit-linear-gradient(top, #526A16, #99C624);
        background-image: -moz-linear-gradient(top, #526A16, #99C624);
        background-image: -ms-linear-gradient(top, #526A16, #99C624);
        background-image: -o-linear-gradient(top, #526A16, #99C624);
        background-image: linear-gradient(to bottom, #526A16, #99C624);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        text-decoration: none;
    }
        

    .btn-administradores{
        background-image: -moz-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: -ms-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: -o-linear-gradient(top, #63C2DE, #1E62D0);
        background-image: linear-gradient(to bottom, #63C2DE, #1E62D0);    

    }

    .row-top-dash{
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .col-left-dash{
        padding-right: 5vw;
    }
    .col-right-dash{
        padding-left: 5vw;
    }

}

#div-periodosSelect{
    .row{
        padding: 5vh;
        margin-left: auto;
        margin-right: auto;
    }
    .col-label-periodosSelect{
        text-align: center;
    }
    .label-periodosSelect{
        font-family: "Comic Sans MS", cursive, sans-serif;
        font-size: 18px;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        color: #FFFF;
        font-weight: 700;
        text-decoration: none;
        font-style: normal;
        font-variant: small-caps;
        text-transform: uppercase;
    }
    .btn-periodo{
        width: 38vw;
        height: 7vh;
        margin: 0 auto;
    }

    .title-periodos{
        font-family: "Comic Sans MS", cursive, sans-serif;
        font-size: 22px;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        font-style: normal;
        font-variant: small-caps;
        text-transform: uppercase;
        margin: 0 auto;

    }
}

.textarea-selectsubject{
    margin: 0 auto;
    min-height: 17vh;
    
}

.textarea-boletin{
    margin: 0 auto;
    min-height: 17vh;
}

.textarea-boletin-nota{
    text-align: center;
    max-width: 124px;
    font-size: 18px;
    font-weight: bolder;
}

#head-table-materias{
    font-weight: bolder;
    text-align: center;
    .letra-peq{
        font-weight: normal;
        font-size: 12px;
    }

}


#div-createteacher{
    strong{
        font-size: 9px;
    }
    .row-dir-cu{
        margin-top:15px
    }
    .css-2b097c-container{
        display: table;
    }

}

.table th, .table td{
    vertical-align: middle;
    margin: 0 auto;
}

#vinculacion-contrato{
    width: 20px;
}

.td-nota-estudiante{
    text-align: center;
    .input-notas{
        text-align: center;
    }
}

.div-select-materia{
    margin-bottom: 25px;
    .label-select-materia{
        font-weight: bolder;
    }
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
    text-align: center;
}

.ReactTable .rt-tbody .rt-td{
    text-align: center;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgba(4, 4, 4, 0.02);
    border-bottom: 1px solid rgb(183, 182, 182);
}

#id-verificar-promover{
    margin-top: 25vh;
    text-align: center;
    label{
        font-size: 18px;
    }
    input{
        border:1px solid rgb(183, 182, 182) !important;
        width: 20vw;
        margin: 0 auto;
        font-size: 18px;
    }
    button{
        margin-top: 25px;
        width: 125px;
        font-size: 18px;
        height: 40px;
    }

}

#id-boton-promover{
    margin-top:25vh;
    label{
        font-size: 20px;
    }
    .button-promover{
        margin-top: 15px;
        height: 48px;
        font-size: 25px;
    }
}

#id-reporteGeneral{
    .row-filtro-reporte{
        margin-top: 20px;
    }
    .input-search{
        width: 300px;
        //margin: 0 auto;
    }
    .label-filtro-genero{
        float: right;
        font-weight: bolder;
        font-size: 18px;
        padding-top: 6px;
    }
    
    .input-range{
        width: 300px;
        //margin: 0 auto;
        margin-top: 8px;
    }

    .info-table-reporte{
        margin-top: 38px;
        font-size: 18px;
    }
    label{
        margin:0 auto;
    }
    .table-responsive{
        margin-top: -34px;
    }
    
}

.div-reporteNota{
    .table-responsive{
        margin-top: 34px !important;
    }

    .input-search-reporteNota{
        border: 1px solid black !important;
        margin-top: 2px;
    }
}

.div-crear-candidatos{
    text-align: center;
    
    strong{
        font-size: 18px;
    }
    .card{
        width: 100%;
        margin-top: 15px;
    }
    label{
        font-weight: bolder;
    }
    .input-titulo-votacion{
        font-size: 17px;
        font-weight: bolder;
    }
    .label-titulo-votacion{
        font-size: 17px;
    }
    .css-2b097c-container {
        margin-top: 25px;
    }
    #padre{
        width: 100%;
        div{
            margin-top: 20px;
            label{
                font-size: 15px;
                font-weight: bolder;
            }
            input{
                width:56%;
            }
  
        }
    }

    #padreEvaluacion{
        width: 100%;
        .div-hijo{
            margin-top: 20px;
        }
        div{
            .label-pregunta{
                font-weight: bolder;
                width: 100%;
                font-size: 18px;
            }
            label{
                font-size: 15px;   
                font-weight: initial;            
            }
            input{
                width:56%;
            }
            
            .div-row{
                width: 100%;
            }
            textarea{
                width: 65%;
            }
        }
    }

    #button-guardar-candidato{
        background-color: green;
        height: 60px;
        font-size: 20px;
        font-weight: bolder;
    }
}

.div-votar-candidatos{
    margin-top:25px;
    strong{
        font-size: 1.5rem;
    }

}

.div-evaluar-docente{
    margin-top: 20px;
}

.div-resultados-docentes{
    
    label{
       font-weight: inherit;
    }
    strong{
        margin-left: 15px;
    }
    .div-indicadores{
        border:1px solid black;
        margin-right: 40%;
        label{
            margin-left: 3vw;
        }
        strong{
            margin-left: 6vw;
        }
    }
}

.indicador1{
    margin-left: 6vw !important;
}
    
.div-modificar-web{
    padding-top: 20px;
    input{
        width: 30vw;
    }
    .row{
        margin-top: 20px;
        align-items: center;
    }
    label{
        margin-right: 8px;
    }
}

